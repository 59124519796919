<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">CONTACT US</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">Contact us</span>
            </div>
        </div>
        <div class="scontainer">
            <div class="section">
                <div class="sectionin">
                    <div class="stit">문의하기</div>
                    <p class="stxt">
                        TINTEC C&C는 모든 문의에 대해 최상의 서비스를 제공할 수 있도록 노력하고 있습니다.
                    </p>

                    <div class="contactform">
                        <form id="contactForm" class="contact-form" action="php/contact-form.php" method="POST">
                            <div class="form-row">

                                <div class="form-group col-lg-6 col-md-6">
                                    <label>이름</label>
                                    <input type="text" value="" data-msg-required="Please enter your name."
                                           maxlength="100" class="form-control cinput" name="name" id="name"
                                           placeholder="이름을 입력해주세요" required>
                                </div>
                                <div class="form-group col-lg-6 col-md-6">
                                    <label class="required font-weight-bold text-dark text-2">회사명</label>
                                    <input type="text" value="" data-msg-required="Please enter your name."
                                           maxlength="100" class="form-control cinput" name="name" id="name"
                                           placeholder="회사명을 입력해주세요" required>
                                </div>
                            </div>

                           <div class="form-row">
                                <div class="form-group col-lg-6 col-md-6">
                                    <label class="required font-weight-bold text-dark text-2">Email</label>
                                    <input type="email" value="" data-msg-required="Please enter your email address."
                                           placeholder="이메일을 입력해주세요"
                                           data-msg-email="Please enter a valid email address."
                                           maxlength="100" class="form-control cinput" name="email" id="email" required>
                                </div>

                                <div class="form-group col-lg-6 col-md-6">
                                    <label class="required font-weight-bold text-dark text-2">이름</label>
                                    <input type="text" value="" data-msg-required="Please enter your name."
                                           placeholder="연락처를 입력해주세요" maxlength="100" class="form-control cinput"
                                           name="name" id="name" required>
                                </div>


                            </div>


                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="required font-weight-bold text-dark text-2">문의 내용</label>
                                    <textarea maxlength="5000" data-msg-required="Please enter your message." rows="6"
                                              placeholder="내용을 입력해주세요" class="form-control" name="message" id="message"
                                              required></textarea>
                                </div>
                            </div>

                        </form>

                    </div>
                    <div class="contactform">
                        <h2>개인정보 수집 및 이용에 대한 동의</h2>
                        <div class="persarea">
                            <p>개인 정보 사용 계약</p>
                            <p>Tintec C & C가 제공하는 서비스 (이하 "회사"라고 함)를 사용하기 위해 회사는 개인 정보를 수집 / 사용하고 다음과 같이 개인 정보 취급을 위임한다는
                                데 동의합니다.
                            </p>
                            <p>①. 수집 품목<br>
                                -사용자 이름 (법인의 경우 법인 명), 생년월일 (법인의 경우 법인 등록 번호), 주소, 성별, 국내 또는 해외
                                연계 정보 (CI), 중복 등록 확인 정보 (DI)
                                연락처 및 계정 정보와 같은 민감한 정보
                            </p>
                            <p>②. 사용 목적<br>
                                -사용 응용 프로그램, 제품 사용 정보 등의 분석 및 세분화를 통한 사용자 서비스 사용 선호도 분석-서비스 관련 상담 및 불만 처리
                                불법 사용 및 시운전 방지
                                -사용자의 요청에 따라 개인 정보 및 서비스 정보 제공
                            </p>
                            <p>③. 개인 정보의 보유 및 이용 기간<br>
                                1. 이용자의 개인 정보 이용 목적이 달성되거나 보유 및 보유 기간이 종료 된 때에는“회사”는 지체없이 해당 정보를 파기하여 별도로 보유하지 않습니다. 그러나
                                관련 법령 및 회사 정책에 따른 보존 목적 및 보존 기간은 다음과 같습니다.
                                소비자 불만 또는 분쟁 기록 : 3 년 (전자 상거래 등에서의 소비자 보호에 관한 법률)
                                2. 회사 내부 정책 -ID 확인 및 차단 기록, 사업자 등록 번호, 사업장 주소 및 AS 기록에 따른 정보 보유 이유
                                보존 기간은 3 년입니다.
                            </p>
                            <p>개인 정보 정책</p>
                            <p>1. 개인 정보 처리 목적<br>
                                ( 'Https : www.tinteccnc.co.kr'또는 'tintec cnc')은 다음 목적을 위해 개인 정보를 처리하며 다음 목적 이외의 목적으로
                                사용하지 않습니다.
                                -고객에게 서비스 제공을 통해 고객의 가입 의도 확인 및 사용자 식별, 회원 유지. 재화 나 용역의 공급으로 인한 금액의 관리, 지급
                            </p>
                            <p>2. 개인 정보의 처리 및 보유 기간<br>
                                ① ( 'https : www.tinteccnc.co.kr'또는 'tintec cnc')은 데이터 주체로부터 개인 정보를 수집 할 때 동의 한 개인 정보를
                                가지고 있습니다. ․ 사용 기간 또는 법률에 따른 개인 정보의 보유 사용 기간 내에 개인 정보를 처리합니다. 보유.
                                ② 구체적인 개인 정보 처리 및 보유 기간은 다음과 같습니다.
                                전자 상거래 계약 공급 철회, 지불, 서비스, 물품 등 기록 : 5 년
                                -고객 등록 및 관리 : 서비스 이용 계약 또는 회원 자격 해지 전까지는 채권 만 부채 관계가 남아 있으면 관련 채권; 부채 관계가 해결 될 때까지.
                            </p>
                            <p>3. 개인 정보의 제 3 자 제공에 관한 사항<br>
                                ① ( 'https : www.tinteccnc.co.kr'또는 'Tintec cnc')은 개인 정보 보호법 제 17 조 및 제 18 조의 규정에 따른
                                경우에만 개인 정보를 제 3 자에게 공개하지 않습니다. 제공하십시오.
                                ② 회사는 회원의 동의없이 제 3 자에게 개인 정보를 제공하거나 공개하지 않습니다. 단, 다음은 예외입니다.
                                1. 회원이 사전에 공개 또는 제공하기로 동의 한 경우
                                2. 관련법에 따라 개인 정보를 제출할 의무가있는 경우 (법에 의거 또는 법정, 조사 기관 또는 기타 행정 기관의 요청에 따라 자료의 제공이 의무화 된 경우)
                                ② ( 'https : www.tinteccnc.co.kr')은 다음과 같이 개인 정보를 제 3 자에게 제공합니다.
                                -개인 정보 수령인 : 한국 서버 오스틴
                                -수신자의 개인 정보 이용 목적 : 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록
                                받는 사람의 보존. 사용 기간 : 위탁 계약이 끝날 때까지
                            </p>
                            <p>-개인 정보를받는 사람 : 하나 은행
                                수취인 별 개인 정보의 이용 목적 : 성명, 회사 명, 신용 카드 정보, 은행 계좌 정보
                                받는 사람의 보존. 사용 기간 : 위탁 계약이 끝날 때까지
                            </p>
                            <p>-개인 정보를받는 사람 : 우리 은행
                                수취인의 개인 정보 이용 목적 : 성명, 회사 명, 신용 카드 정보, 은행 계좌 정보
                                받는 사람의 보존. 사용 기간 : 위탁 계약이 끝날 때까지
                            </p>
                            <p>4. 정보 주체의 권리 행사<br>
                                데이터 주체 및 법정 대리인의 권리와 의무 및 행사 방법 개인 정보 주체로서 사용자는 다음과 같은 권리를 행사할 수 있습니다.
                                ① 정보 주체는 Tintec cnc Co., Ltd. (http://www.tinteccnc.co.kr)와 관련하여 언제든지 개인 정보 보호와 관련된 권리를
                                행사할 수 있습니다.
                                1. 개인 정보 접근 요청
                                2. 오류 발생시 정정 요청
                                3. 요청 삭제
                                4. 처리 중지 요청
                            </p>
                            <p>5. 처리 할 개인 정보 항목을 작성하십시오<br>
                                ① ( 'https : www.tinteccnc.co.kr'또는 'Tintec cnc')은 다음 개인 정보 항목을 처리합니다.
                                -필수 항목 : 이메일, 휴대폰 번호, 집 주소, 사무실 주소, 집 전화 번호, 사무실 전화 번호, 비밀번호, 로그인 아이디, 성별, 생년월일, 이름, 회사
                                명, 주민등록번호, 신용 카드 정보, 은행 계좌 정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 지불 기록
                            </p>
                            <p>6. 개인 정보의 파기.<br>
                                원칙적으로 개인 정보 처리 목적이 달성되면 개인 정보는 지체없이 파기합니다. 파기 절차, 마감일 및 방법은 다음과 같습니다.
                                ① 파기 절차
                                사용자가 입력 한 정보는 목적을 달성 한 후 별도의 DB (종이의 경우 별도의 문서)로 전송되며 내부 정책 및 기타 관련 법률에 따라 일정 기간 동안 즉시
                                저장 또는 파기됩니다. 현재 DB로 전송 된 개인 정보는 법으로 요구되지 않는 한 다른 목적으로 사용되지 않습니다.
                                ② 파괴
                                개인 정보의 보유 기간이 경과 한 경우, 보유 기간 종료 후 5 일 이내에 이용자의 개인 정보가 불필요하게되면, 개인 정보의 처리 목적 달성, 서비스 폐지 등
                                개인 정보가 불필요하게됩니다. 또는 사업 종료. 개인 정보는 처리가 불필요하다고 간주 한 날로부터 5 일 이내에 파기됩니다.
                                1. 출력 형태 : 분쇄기로 분쇄 또는 소각으로 파괴
                                2. 전자 파일 형식 : 레코드를 재생할 수없는 기술적 방법을 사용하여 삭제
                            </p>
                            <p>7. 개인 정보 자동 수집의 설치, 운영 및 거부<br>
                                ① Tintec cnc Co., Ltd.는 때때로 정보 주체의 사용 정보를 저장하고 검색하는 '쿠키'를 사용합니다.
                                ② Tintec cnc Co., Ltd.는 쿠키를 사용하여 정보 주체의 이름, ID, 이메일 등에 관한 정보를 찾습니다.이 쿠키 정보는 사용자 특성을 식별하는
                                데 사용될 수 있습니다. 대상 광고 및 이벤트 이벤트에도 사용할 수 있습니다.
                                ③ 정보 주체는 웹 브라우저에서 옵션 기능을 조정하여 쿠키를 선택적으로 수락 할 수 있습니다. 모든 쿠키를 수락하거나 쿠키가 설치 될 때 알림을 보내거나 모든
                                쿠키를 거부 할 수도 있습니다. 그러나 고객이 쿠키 설치를 거부하면 서비스 사용이 불편하거나 서비스 제공이 어려울 수 있습니다.
                                ④ 쿠키 설정을 거부하는 방법 예 : 웹 브라우저의 옵션을 선택하여 쿠키 설정을 거부하거나, 모든 쿠키를 허용하거나, 쿠키를 저장할 때마다 확인하거나, 모든
                                쿠키의 저장을 거부 할 수 있습니다.
                                -설정 방법 예 (Internet Explorer의 경우) : 웹 브라우저 상단의 도구> 인터넷 옵션> 개인 정보

                            </p>
                            <p>8. 개인 정보 관리 책임자 작성<br>
                                ① Tintec cnc Co., Ltd. ( 'https : www.tinteccnc.co.kr'또는 'Tintec cnc'이하)는 개인 정보 처리를 담당하며
                                개인 정보 처리와 관련된 정보 주체의 불만 및 손해를 처리합니다. 다음과 같습니다. 프라이버시 책임자가 지정되었습니다.
                            </p>
                            <p>■ 개인 정보 보호 책임자<br>
                                이름 : 이은정<br>
                                문의 : 070-7826-5670,<br>
                                이메일 : ejlee@tintec.co.kr<br>
                                F A X : 0505-245-8245<br>
                                ※ 개인 정보 보호 담당 부서에 연결됩니다.
                            </p>
                            <p>■ 개인 정보 보호 부<br>
                                부서명 : 관리팀<br>
                                문의 : 070-7826-5670,<br>
                                이메일 : ejlee@tintec.co.kr<br>
                                F A X : 0505-245-8245
                            </p>
                            <p>9. 정보 주체의 침해 권을 완화하는 방법<br>
                                ① 회사 서비스 사용으로 인해 발생한 개인 정보 침해 신고는 개인 정보 관리자 또는 담당 부서에보고 할 수 있습니다. 회사는 사용자 보고서에 신속하고 완전하게
                                대응할 것입니다.
                                ② 정보 주체는 Tintec cnc Co., Ltd. (http://www.tinteccnc.co.kr)의 서비스 (또는 사업)를 이용할 때 발생할 수있는
                                모든 개인 정보 관련 문의, 불만 및 구제책을 책임집니다. 보호 담당자 및 담당 부서에 문의 할 수 있습니다. Tintec cnc Co., Ltd.
                                (http://www.tinteccnc.co.kr)는 정보 주체의 문의에 대해 지체없이 답변하고 처리 할 수 있습니다.
                                개인 정보 침해에 대해보고하거나 상담해야하는 경우 다음 기관에 문의하십시오. 개별 분쟁 해결위원회 (www.1336.or.kr/1336)㉡. 정보 보호 마크
                                인증위원회 (www.eprivacy.or.kr/02-580-0533~4)㉢. 대검찰청 인터넷 범죄 수사 센터
                                (http://icic.sppo.go.kr/02-3480-3600)㉣. 경찰청 사이버 테러 대응 센터 (www.ctrc.go.kr/02-392-0330)
                            </p>
                            <p>10. 개인 정보 보호 정책 변경<br>
                                ① 본 개인 정보 처리 방침은 발효 일부터 적용되며, 법령에 따라 추가, 삭제 및 정정이있는 경우에는 변경 발효 일 7 일 전까지 공지를 통해 통지합니다.
                            </p>
                            <p>실제 정보<br>
                                개인 정보의 안전을 보장하기위한 조치 ( 'Tintec cnc')는 개인 정보 보호법 제 29 조에 따라 안전을 보장하기 위해 필요한 기술적, 관리적, 물리적
                                조치를 취합니다.
                                a. 정기적 인 자기 감사
                                개인 정보 취급의 안전을 확보하기 위해 정기적으로 (1 분기에 1 회) 자체 감사를 실시합니다.
                                b. 개인 정보 취급 직원의 최소화 및 교육
                                개인 정보를 취급하는 직원을 지정하고, 담당자에게 최소화하고, 개인 정보 관리를위한 조치를 취합니다.
                                c. 내부 관리 계획 수립 및 시행
                                우리는 개인 정보를 안전하게 취급하기위한 내부 관리 계획을 수립하고 시행했습니다.
                                d. 해킹에 대한 기술적 조치
                                Tintec cnc Co., Ltd. ( 'Tintec cnc')는 보안 프로그램을 설치하고 주기적으로 업데이트 및 검사하며 해킹 또는 컴퓨터 바이러스로 인한
                                개인 정보 유출 및 손상을 방지하기 위해 외부에서 액세스가 제어되는 영역에 시스템을 설치합니다. 물리적으로 모니터링 및 차단됩니다.
                                e. 액세스 기록 유지 및 위조 방지
                                당사는 6 개월 이상 개인 정보 처리 시스템에 대한 액세스 기록을 유지하고 관리하며 보안 기능을 사용하여 액세스 기록이 변경, 도난 또는 분실되지
                                않도록합니다.
                                f. 개인 정보에 대한 액세스 제한
                                개인 정보를 취급하는 데이터베이스 시스템에 대한 액세스 권한을 부여, 수정 및 삭제하여 개인 정보에 대한 액세스를 제어하고 침입 방지 시스템을 사용하여
                                외부로부터의 무단 액세스를 제어하는데 필요한 조치를 취합니다.
                                g. 문서 보안을 위해 잠금 사용
                                개인 정보 및 보조 저장 매체가 포함 된 문서는 잠금 장치가있는 안전한 장소에 저장됩니다.
                                h. 권한이없는 사람을위한 액세스 제어
                                우리는 개인 정보를 보관할 별도의 전자 및 물리적 보관 장소를 설정하고 출입 통제 절차를 수립하고 운영했습니다.
                            </p>
                            <p>12. 어린이 개인 정보<br>
                                14 세 미만의 어린이의 개인 정보를 보호하고 광고 메일 노출을 방지하기 위해 14 세 미만의 어린이는 회원으로 등록 할 수 없습니다. 서비스의 특성상 14
                                세 미만 어린이의 개인 정보가 불가피하게 필요한 경우, 법정 대리인이 동의를 확인해야합니다.
                            </p>
                            <p>13. 통지 의무<br>
                                개인 정보 보호 정책 내용의 추가, 삭제 또는 수정이있는 경우, 개정 7 일 전에 통지하는 것이 원칙이며, 회원에게 불리한 변경 사항은 개정 30 일 전에
                                통지해야합니다.
                            </p>
                            <p>
                                부록
                                이 정책은 2019 년 7 월 1 일부터 시행됩니다.

                            </p>


                        </div>

                        <div class="perschk">
                            <input type="checkbox">
                            <div class="cklabel">개인정보 수집 및 이용에 대해 동의합니다. <span class="fblue">(필수)</span></div>
                        </div>

                    </div>

                    <div class="inquirybtn">
                        <button>문의하기</button>
                    </div>

                </div>

            </div>

            <div class="section bggrey">
                <div class="sectionin">
                    <div class="stit">찾아오시는 길</div>
                    <div class="maparea">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.8506130274573!2d126.825057616084!3d37.55858323233972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9d06fa8ee8bb%3A0xd3341235691371f3!2z66mU7Yq466Gc67mE7KaI7YOA7JuM!5e0!3m2!1sko!2skr!4v1596452857673!5m2!1sko!2skr"
                                width="1200" height="486" frameborder="0" style="border:0;" allowfullscreen=""
                                aria-hidden="false" tabindex="0"></iframe>
                    </div>

                    <div class="mapinfo row">
                        <div class="infobox col-lg-8">
                            <h2>Our Office</h2>
                            <div>
                                <span><img :src="require('@/assets/img/sub/cont_icon01.svg')"></span>
                                <span class="infotit">Address</span>
                                <span>서울특별시 강서구 마곡동 마곡중앙2로 5,메트로비즈타워 504호</span>
                            </div>
                            <div>
                                <span><img :src="require('@/assets/img/sub/cont_icon02.svg')"></span>
                                <span class="infotit">E-mail</span>
                                <span>tinteccnc@tinteccnc.com</span>
                            </div>
                            <div>
                                <span><img :src="require('@/assets/img/sub/cont_icon03.svg')"></span>
                                <span class="infotit">Phone</span>
                                <span>02-6959-5701</span>
                            </div>
                        </div>

                        <div class="infobox col-lg-4">
                            <h2>business Hours</h2>
                            <div>
                                <span><img :src="require('@/assets/img/sub/cont_icon04.svg')"></span>
                                <span>Monday - Friday : 9am to 6Pm</span>
                            </div>
                            <div>
                                <span><img :src="require('@/assets/img/sub/cont_icon04.svg')"></span>
                                <span>Saturday-Sunday : Closed</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <!-- <section>
                <common-site-map></common-site-map>
            </section>-->
        </div>
        <common-footer></common-footer>
    </div>
</template>

<style scoped>
    /*_-------------------------------------------------------------------서브페이지 공통*/

    /*pc header start*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background: #f9f9f9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul >  li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        /*position: absolute;
        top: 53px;
        z-index: 99;
        padding: 0;
        background-color: red;
        width: 100%;*/
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/

    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;
    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
        padding-left: 20px;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }

    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        padding: 100px 40px;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .scontainer .section .sectionin .stit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 70px;
        font-weight: 900;
        padding-top: 20px;
        text-align: center;
    }

    .scontainer .section .sectionin .stxt {
        font-size: 1.8rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 12px;
        line-height: 27px;
    }

    .scontainer .section.bggrey {
        background-color: #FAFAFA;
    }

    /*_-------------------------------------------------------------------서브페이지 공통*/
    .contactform {
        margin-top: 58px;
    }

    .contactform .form-row {
        margin-bottom: 10px;
    }

    .contactform .form-row input.cinput {
        padding: 16px 24px;
        font-size: 1.6rem;
        color: #777777;
        letter-spacing: -0.8px;
        border: 1px solid #dcdcdc;
        background-color: #f9f9f9;
        height: 58px;
    }

    .contactform .form-row textarea {
        background-color: #f9f9f9;
        border: 1px solid #dcdcdc;
        font-size: 1.6rem;
        letter-spacing: -0.8px;
        line-height: 24px;
        padding: 16px 24px;
    }

    .contactform .form-row label {
        display: none;
    }

    .contactform h2 {
        font-size: 2rem;
        font-weight: 900;
        letter-spacing: -1px;
        margin-bottom: 16px;
    }

    .contactform .persarea {
        height: 180px;
        background-color: #f9f9f9;
        border: 1px solid #dcdcdc;
        overflow-y: scroll;
        padding: 16px 24px;
    }

    .contactform .persarea p {
        pdding-bottom: 16px;
        color: #777777;
        line-height: 20px;
        font-size: 1.3rem;
        letter-spacing: -0.65px;
    }


    .contactform .perschk {
        text-align: center;
        margin-top: 54px;
    }

    .contactform .perschk input[type="checkbox"] {
        display: inline-block;
        width: 22px;
        height: 22px;
        border: 1px solid #707070;
        vertical-align: sub;
    }

    .contactform .perschk .cklabel {
        display: inline-block;
        font-size: 1.8rem;
        color: #333;
        letter-spacing: -0.9px;
        margin-left: 10px;
    }

    .fblue {
        color: #2d84e2
    }

    .inquirybtn {
        text-align: center;
        margin-top: 60px;
    }

    .inquirybtn button {
        color: #fff;
        font-size: 1.8rem;
        letter-spacing: -0.27px;
        font-weight: 900;
        padding: 16px 92px;
        background: #2d84e2;
        border: 0px;
    }

    .maparea {
        margin-top: 58px;
    }

    .mapinfo {
        margin-top: 64px;
    }

    .mapinfo .infobox {
        display: inline-table;
    }

    .mapinfo .infobox h2 {
        font-size: 2.2rem;
        color: #000;
        letter-spacing: -1.1px;
        font-weight: 900;
        margin-bottom: 18px;
    }

    .mapinfo .infobox div span {
        margin-right: 8px;
    }

    .mapinfo .infobox div .infotit {
        font-size: 2rem;
        letter-spacing: -1px;
        color: #000;
    }

    .mapinfo .infobox div span {
        color: #727272;
        font-size: 2rem;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }

    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .subtop .subtopin img {
            width: 418px;
            display: inline-block;
            margin-left: 0%;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */

        .subtop .subtopin {
            width: 1200px;
            margin: 0 4%;
        }

        .scontainer .section .sectionin {
            width: 100%;
        }

        .contactform {
            width: 100%;
        }

        .mapinfo {
            margin-top: 10px;
        }

        .mapinfo .infobox h2 {
            font-size: 2.2rem;
            color: #000;
            letter-spacing: -1.1px;
            font-weight: 900;
            margin: 10px 0 6px 0;
        }

        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }


    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {
        /*grid*/
        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .col-lg-6 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .scontainer .section .sectionin .stxt {
            font-size: 1.4rem;
            color: #000;
            line-height: 18px;
            letter-spacing: -0.9px;
            text-align: center;
            padding-top: 12px;
            line-height: 27px;
        }

        .subtop {
            width: 100%;
            height: 97px;
            border-bottom: 1px solid #0F70B7;
        }

        .subtop .subtopin {
            width: 100%;
            margin: 0 auto;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 10%;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }


        .scontainer .section {
            padding: 32px 20px;
        }

        .scontainer .section .sectionin {
            width: 100%;
            overflow: hidden;
        }

        .scontainer .section .sectionin .stit {
            text-align: center;
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            letter-spacing: -1px;
            line-height: 40px;

        }

        .contactform .perschk {
            text-align: center;
            margin-top: 34px;
        }

        .inquirybtn {
            text-align: center;
            margin-top: 42px;
            padding-bottom: 20px;
        }

        .form-row>.col, .form-row>[class*=col-] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .maparea {
            margin-top: 20px;
        }

        .form-row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .contactform {
           margin-top: 24px;
        }


        .contactform h2 {
            font-size: 1.6rem;
        }

        .mapinfo {
            margin-top: 0px;
        }

        .mapinfo .infobox h2 {
            font-size: 1.6rem;
            line-height: 20px;
            margin: 30px 0 6px 0;
        }

        .mapinfo .infobox div .infotit {
            font-size: 1.4rem
        }

        .mapinfo .infobox div span {
            font-size: 1.4rem;
        }

        .contactform .perschk .cklabel {
            display: inline-block;
            font-size: 1.3rem;
            color: #333;
            letter-spacing: -0.9px;
            margin-left: 10px;
        }


        .five_content {
            display: none;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }

    }


</style>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";
    export default {
        name: "ContactUs",
        components: {CommonFooter, CommonHeader}
    }
</script>